<template>
    <div class="list-filter">
        <!-- filter -->
        <div class="list-filter-group">
            <slot name="filter"></slot>
        </div>
        <!-- sort -->
        <div class="list-filter-sort">
            <!-- list -->
            <ul class="list-filter-sort-list">
                <li v-for="item in sortList"
                    :key="item.value">
                    <a href="javascript:;"
                        @click="handleClick(item.value)"
                        :class="{
                            'selected-sort': item.value == selected_sort
                        }">
                        {{item.label}}
                    </a>
                </li>
            </ul>
            <!-- num -->
            <div>
                共
                <span class="list-filter-sort-num">{{18253 + countNum}}</span>
                件商品
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selected_sort: ''
            }
        },
        props: {
            sortList: Array,
            countNum: [ String, Number ],
            selectedSort: {
                type: [ String, Number ],
                default: ''
            }
        },
        watch: {
            selectedSort: {
                handler: function(value){
                    this.selected_sort = value;
                },
                immediate: true
            }
        },
        methods: {
            handleClick(val) {
                this.$emit('handleSort', val);
            }
        },
    }
</script>

<style scoped>
.list-filter{
    margin-top: 30px;
}
/* filter */
.list-filter-group,
.list-filter-sort{
    padding: 20px 30px;
    background-color: #f8f8f8;
    border-radius: 6px;
}
.list-filter-group > div + div{
    margin-top: 10px;
}
/* sort */
.list-filter-sort{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
}
.list-filter-sort-list{
    display: flex;
}
.list-filter-sort-list > li + li{
    margin-left: 10px;
}
.list-filter-sort-list a{
    display: block;
    padding: 2px 16px;
    border-radius: 30px;
    color: #303133;
}
.list-filter-sort-list a.selected-sort{
    background-color: var(--color);
    color: #fff;
}
.list-filter-sort-num{
    color: var(--color);
}
</style>